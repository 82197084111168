/**
 *
 * Registration Page
 *
 *
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Home from 'containers/Home';
import SEO from 'components/seo';
import theme from 'utils/theme';

const RegistrationPage = () => (
  <ThemeProvider theme={theme}>
    <Container>
      <SEO title="Registration" />
      <Home />
    </Container>
  </ThemeProvider>
);

export default RegistrationPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;
